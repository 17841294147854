import StoryPage from "../../components/story-page";
/** @jsx jsx */
import { jsx } from "theme-ui";

const ThisPage = ({ location }) => {
  const imgUrl = "/images/people/ronald-suresh-persaud.jpg";
  const fullName = `Ronald Suresh Persaud`;
  const zoomUrl =
    "https://us02web.zoom.us/j/85885952625?pwd=SjNsekpDdS8ybmV6U0FHUVcrbmF6QT09";
  const zoomPhoneNumber = "(669) 900-9128";
  const zoomMeetingId = "858 8595 2625";
  const zoomMeetingPasscode = "252174";
  const birthDate = new Date(1961, 4 - 1, 17);
  const deathDate = new Date(2021, 1 - 1, 14);
  const birthDeathFooter = "";
  const memorialDate = new Date(2021, 1 - 1, 22);
  const memorialTime = "9am EST";
  const inviteText = `It is with great sadness that the family of Ronald Suresh Persaud announce his sudden passing on Thursday, January 14, 2021 at the age of 59.
\nRonald will be lovingly remembered by his wife of 39 years, Mitra, his children and grandchildren.
\nIn lieu of a physical ceremony, the family of Ronald invites you to a virtual service honouring his life.
  \nA Religious Service will be held on Friday, January 22, 2021 at 9:00 a.m. via online memorial and reading of the eulogy and guest speakers will begin at 10:15 a.m. through 11:25 a.m.
  \nThe cremation is open to all and will take place at 11:50a.m. at Metro Crematory, 715 S Bluford Ave, Ocoee, FL 34761. Please wear a mask.`;
  const obituary = ``;
  const storiesRecordUrl = "";
  const storiesSubmissionsUrl = "";
  const recordingEmbedDirectUrl = [
    {
      url: "https://storage.googleapis.com/afterword-static/recordings/Ronald%20Persaud.mp4",
      poster: "/images/people/ronald-suresh-persaud-poster.png",
    },
  ];

  return (
    <StoryPage
      location={location}
      imgUrl={imgUrl}
      fullName={fullName}
      zoomUrl={zoomUrl}
      zoomPhoneNumber={zoomPhoneNumber}
      zoomMeetingId={zoomMeetingId}
      zoomMeetingPasscode={zoomMeetingPasscode}
      optInviteChild=""
      customColumnSpacing="1fr 3fr"
      birthDate={birthDate}
      deathDate={deathDate}
      birthDeathFooter={birthDeathFooter}
      memorialDate={memorialDate}
      memorialTime={memorialTime}
      inviteText={inviteText}
      obituary={obituary}
      storiesRecordUrl={storiesRecordUrl}
      storiesSubmissionsUrl={storiesSubmissionsUrl}
      recordingEmbedDirectUrl={recordingEmbedDirectUrl}
    >
      <p>
        Memorial Donations in Ronald’s name may be made to{" "}
        <a
          href="https://saraswatidevimandir.com"
          target="_blank"
          rel="noreferrer"
        >
          Saraswati Devi Mandir
        </a>
        .
      </p>
    </StoryPage>
  );
};

export default ThisPage;
